// store/modules/websocket.js
import baseUrls from "@/api/baseUrls";

const websocket = {
  state: {
    socket: null,
    isConnected: false,
    isConnecting: false,
    connectionPromise: null,
  },

  mutations: {
    SET_SOCKET(state, socket) {
      state.socket = socket;
    },
    SET_CONNECTION_STATUS(state, isConnected) {
      state.isConnected = isConnected;
      state.isConnecting = false;
    },
    SET_CONNECTION_PROMISE(state, promise) {
      state.connectionPromise = promise;
    },
  },

  actions: {
    initWebSocket({ commit, dispatch }, userId) {
      // Connect to your WebSocket server
      // if (!websocket.state.socket && !websocket.state.isConnecting) {
      //   let ws = `${baseUrls.ws}/platformAsync/` + userId;
      //
      //   const socket = new WebSocket(ws);
      //
      //   const connectionPromise = new Promise((resolve, reject) => {
      //     socket.addEventListener("open", () => {
      //       commit("SET_SOCKET", socket);
      //       commit("SET_CONNECTION_STATUS", true);
      //       resolve();
      //     });
      //
      //     socket.addEventListener("close", () => {
      //       commit("SET_SOCKET", null);
      //       commit("SET_CONNECTION_STATUS", false);
      //       reject(new Error("WebSocket connection closed"));
      //     });
      //
      //     // WebSocket message received
      //     socket.addEventListener("message", (event) => {
      //       console.log("WebSocket message received:", event.data);
      //       let title = `消息提醒  （12）`;
      //       let messageTitle = "450号订单配送即将超时";
      //       let messageContent = "450号订单快到送达时间了，请及时完成配送";
      //       this.$notify({
      //         title: title,
      //         duration: 0,
      //         showClose: false,
      //         position: "bottom-right",
      //         dangerouslyUseHTMLString: true,
      //         message: `<div class='notifyTitle'>${messageTitle}</div>
      //             <div class='notifyContent'>${messageContent}</div>
      //             <a href="#" class="notifyButton">知道了</a>`,
      //         onClick: function () {
      //           this.close();
      //         },
      //       });
      //       // 添加语言↓
      //     });
      //     // WebSocket error occurred
      //     socket.addEventListener("error", (error) => {
      //       console.error("WebSocket error:", error);
      //     });
      //
      //     // Handle other WebSocket events as needed
      //   });
      //
      //   commit("SET_CONNECTION_PROMISE", connectionPromise);
      //   commit("SET_SOCKET", socket);
      //   commit("SET_CONNECTION_STATUS", true);
      //
      //   return connectionPromise;
      // }
      //
      // return websocket.state.connectionPromise;
    },

    sendWebSocketMessage({ state }, message) {
      if (state.isConnected && state.socket) {
        alert(message);
        state.socket.send(message);
      } else {
        // console.warn("WebSocket is not connected.");
      }
    },
  },
};
export default websocket;
