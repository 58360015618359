import request from "@/utils/request";
import { Loading, MessageBox } from "element-ui";
import axios from "axios";

const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();

let tools = {
  // 语音播报的函数
  /**
   *  text – 要合成的文字内容，字符串
   * lang – 使用的语言，字符串， 例如：“zh-cn”
   * voiceURI – 指定希望使用的声音和服务，字符串
   * volume – 声音的音量，区间范围是0到1，默认是1
   * rate – 语速，数值，默认值是1，范围是0.1到10，表示语速的倍数，例如2表示正常语速的两倍。
   * pitch – 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1
   */
  handleSpeak: function (text) {
    msg.text = text;
    msg.lang = "zh-CN";
    msg.volume = "1";
    msg.rate = 1;
    msg.pitch = 1;
    synth.speak(msg);
  },
  // 语音停止
  handleStop: function (text) {
    msg.text = text;
    msg.lang = "zh-CN";
    synth.cancel(msg);
  },
  jsonToString: function (data) {
    let params = new URLSearchParams();
    for (let obj in data) {
      if (isNaN(data[obj]) && !isNaN(Date.parse(data[obj]))) {
        params.append(obj, new Date(Date.parse(data[obj])));
      } else {
        params.append(obj, data[obj]);
      }
    }
    return params;
  },
};

let ajax = {
  post: function (url, data, fn) {
    // const loading = Loading.service({ fullscreen: true });
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: "post",
        data: data,
      })
        .then((response) => {
          // loading.close();
          const result = response.data;
          if (response.code === -1) {
            reject({ code: response.code, message: response.message });
          } else resolve(result);
        })
        .catch((error) => {
          reject({ code: -1, msg: error === undefined ? "网络错误" : error.message });
        });
    });
  },

  postForm: function (url, data, fn) {
    // const loading = Loading.service({ fullscreen: true });
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      request({
        url: url,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // loading.close();
          const result = response.data;
          if (response.code === -1) {
            reject({ code: response.code, message: response.message });
          } else resolve(result);
        })
        .catch((error) => {
          reject({ code: -1, msg: error === undefined ? "网络错误" : error.message });
        });
    });
  },

  get: function (url, data, fn) {
    // const loading = Loading.service({ fullscreen: true });
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: "get",
        params: data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // loading.close();
          reject({ code: -1, msg: error === undefined ? "网络错误" : error.message });
        });
    });
  },

  delete: function (url, data, fn) {
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: "delete",
        params: data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // loading.close();
          reject({ code: -1, msg: error === undefined ? "网络错误" : error.message });
        });
    });
  },

  put: function (url, data, fn) {
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: "put",
        params: data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // loading.close();
          reject({ code: -1, msg: error === undefined ? "网络错误" : error.message });
        });
    });
  },
};

export { ajax, tools };
