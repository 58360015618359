import Mock from "mockjs";

const getRiderListData = require("./data/getRiderList.json");

Mock.mock('/api/getRiderList', 'get', getRiderListData)

// Mock.mock("/api/getRiderList", function (option) {
//   console.log('mock数据',option);
//   return getRiderListData;
//   // const rbody = JSON.parse(option.body);
//   // if (rbody.reqUrl == "http://10.100.0.8:9081/centermh/api/technicalPlan/planList") {
//   //   return getRiderListData;
//   // }
// });

console.log("%c前端 mock 环境启动成功", "color: #38f;font-weight: bold");
Mock.setup({
  timeout: 200,
});

export default Mock;
