import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRouterMap = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/components/404"),
    hidden: true,
  },
  {
    path: "/index",
    component: () => import("@/components/tabbar"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        component: () => import("@/views/home"),
      },
    ],
  },
  {
    path: "/delivery",
    component: () => import("@/components/tabbar2"),
    hidden: true,
    redirect: "/delivery/deliveryInfo",
    children: [
      {
        meta: { title: "骑手信息管理"},
        path: "deliveryInfo",
        name: "deliveryInfo",
        component: () => import("@/views/delivery/deliveryInfo"),
      },
      {
        meta: { title: "运单管理"},
        name: "deliveryOrder",
        path: "deliveryOrder",
        component: () => import("@/views/delivery/deliveryOrder"),
      },
      {
        meta: { title: "自取货运单管理" },
        path: "deliverySelfTake",
        // path: "deliverySelfPickupGoodsOrder",
        component: () => import("@/views/delivery/deliverySelfPickupGoodsOrder"),
      },
      {
        meta: { title: "自配送运单管理" },
        path: "deliverySelf",
        // path: "deliverySelfDeliveryOrder",
        component: () => import("@/views/delivery/deliverySelfDeliveryOrder"),
      },
      {
        meta: { title: "历史运单" },
        path: "deliveryHistory",
        // path: "deliveryHistoryOrder",
        component: () => import("@/views/delivery/deliveryHistoryOrder"),
      },
      {
        meta: { title: "骑手运效分析" },
        path: "deliveryEffiRide",
        name: "deliveryEffiRide",
        // path: "deliveryEffiRider",
        component: () => import("@/views/delivery/deliveryEffiRider"),
      },
      {
        meta: { title: "骑手运单明细" },
        path: "riderOrderDetail",
        name: "riderOrderDetail",
        component: () => import("@/views/components/DeliveryEffiRider/riderOrderDetail"),
      },
      {
        meta: { title: "自取货骑手运单明细" },
        path: "selfPickupGoodsRiderOrderDetail",
        component: () => import("@/views/delivery/selfPickupGoodsRiderOrderDetail"),
      },
      {
        meta: { title: "渠道运效分析" },
        path: "deliveryEffiChannel",
        // path: "deliveryEffiChannel",
        component: () => import("@/views/delivery/deliveryEffiChannel"),
      },
      {
        meta: { title: "渠道运单管理" },
        path: "channelOrderDetail",
        component: () => import("@/views/delivery/channelOrderDetail"),
      },
      {
        meta: { title: "门店运效分析" },
        path: "deliveryEffiStore",
        // path: "deliveryEffiStore",
        component: () => import("@/views/delivery/deliveryEffiStore"),
      },
      {
        meta: { title: "门店运单管理" },
        path: "storeOrderDetail",
        component: () => import("@/views/delivery/storeOrderDetail"),
      },
      {
        meta: { title: "配送平台管理" },
        path: "deliveryPlatform",
        component: () => import("@/views/delivery/deliveryPlatform"),
      }
    ],
  },
  { path: "*", redirect: "/404", hidden: true },
];

export default new Router({
  mode: "history", //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});
