import { render, staticRenderFns } from "./NoticeBox.vue?vue&type=template&id=4dce5674"
import script from "./NoticeBox.vue?vue&type=script&lang=js"
export * from "./NoticeBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/apple/IdeaProjects/banling-empty-delivery/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dce5674')) {
      api.createRecord('4dce5674', component.options)
    } else {
      api.reload('4dce5674', component.options)
    }
    module.hot.accept("./NoticeBox.vue?vue&type=template&id=4dce5674", function () {
      api.rerender('4dce5674', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/packages/groupList/component/NoticeBox.vue"
export default component.exports