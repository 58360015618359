import router from "./router";
import store from "./store";
import { getToken, removeToken } from "@/utils/auth"; // 验权

const whiteList = ["/"]; // 不重定向白名单
router.beforeEach((to, from, next) => {
  //next();
  if (getToken()) {
    // if (null != store.getters.navs && store.getters.navs.length > 0) {
    next();
    // } else {
    //   removeToken();
    //   next("/");
    // }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/");
    }
  }
});
