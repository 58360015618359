import { render, staticRenderFns } from "./tabItem.vue?vue&type=template&id=23ef6076"
import script from "./tabItem.vue?vue&type=script&lang=js"
export * from "./tabItem.vue?vue&type=script&lang=js"
import style0 from "./tabItem.vue?vue&type=style&index=0&id=23ef6076&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/apple/IdeaProjects/banling-empty-delivery/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23ef6076')) {
      api.createRecord('23ef6076', component.options)
    } else {
      api.reload('23ef6076', component.options)
    }
    module.hot.accept("./tabItem.vue?vue&type=template&id=23ef6076", function () {
      api.rerender('23ef6076', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/packages/main/src/page/manage/tabItem.vue"
export default component.exports