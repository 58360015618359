const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  roles: (state) => state.user.roles,
  navs: (state) => state.user.navs,
  addRouters: (state) => state.permission.addRouters,
  routers: (state) => state.permission.routers,
  websocket: (state) => state.socket,
  websocketStatus: (state) => state.isConnected,
};
export default getters;
